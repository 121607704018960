import * as React from 'react';
const CheckIcon = (props) => (
  <svg
    fill='#FFF'
    width={20}
    height={20}
    viewBox='0 0 1920 1920'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z'
      fillRule='evenodd'
    />
  </svg>
);
export default CheckIcon;
