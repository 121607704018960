import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import toast from 'react-hot-toast';
// import Eye from '@/components/icons/Eye';
// import Eyelock from '@/components/icons/Eyelock';
// import { getSession, signIn } from 'next-auth/react';
import { getCookie, setCookie } from 'cookies-next';
import { loginValidation } from '@/utils/valitation/auth';
// import { event_login } from '@/utils/gtm';
import { loginByEmail, loginByMobile } from '@/services/auth';
import { useRouter } from 'next/router';
import Image from '@/components/global/image';
import userStore from '@/hooks/useStore';

// const toastMsg = (mobile, email, store, isMail) => {
//   const mobileNumber = mobile
//     ? (store === 'uae' ? '00971' : '00966').concat(mobile?.slice(-9))
//     : null;
//   return {
//     uae: {
//       ar: 'تم إزسال رمز التحقق على بريدك الالكتروني '.concat(email || ''),
//       en: 'OTP has been sent on your email address '.concat(email || ''),
//     },
//     ksa: isMail
//       ? {
//           ar: 'تم إزسال رمز التحقق على بريدك الالكتروني '.concat(email || ''),
//           en: 'OTP has been sent on your email address '.concat(email || ''),
//         }
//       : {
//           ar:
//             'تم إرسال رمز التحقق على رقم هاتفك المحمول ' + (mobileNumber || ''),
//           en: 'OTP has been sent on your mobile number ' + (mobileNumber || ''),
//         },
//   };
// };

const Login = ({ t, setAuth, setIsFromLogin }) => {
  // const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState(true);
  const handshake = getCookie('handshake');
  const { locale } = useRouter();
  const store = userStore(locale);

  const handleLogin = async (values, { setFieldError }) => {
    // const toast_msg = toastMsg(
    //   values.mobilenumber,
    //   values.username,
    //   store,
    //   mail
    // )?.[store]?.[locale?.includes('en') ? 'en' : 'ar'];

    setLoading(true);
    if (mail) {
      const res = await loginByEmail(handshake, values.username);
      console.log('res in login', res);
      if (res?.data && res?.status === 200) {
        setIsFromLogin('by email');
        setAuth('Otp');
        toast.success(res?.data?.data?.successmsg);
        setCookie('username', values.username);
        setCookie('mail', mail);
        return setCookie('email', values.username);
      }
      toast.error(t(res?.error?.error));
    } else {
      const { data, status, error } = await loginByMobile(
        handshake,
        values.mobilenumber,
        locale
      );
      console.log('res in handleLogin', {
        data,
        error,
        status,
        success: data?.data?.success,
        successmsg: data?.data?.successmsg,
      });
      if (
        status == 200 &&
        String(data?.data?.success) == 'true' &&
        data?.data?.successmsg
      ) {
        console.log('res in handleLogin____2', { data, error, status });
        setCookie('mobilenumber', values.mobilenumber);
        setCookie('mail', mail);

        toast.success(data?.data?.successmsg);
        setIsFromLogin('by mobile');
        setAuth('Otp');
      } else {
        setFieldError(
          'mobilenumber',
          locale?.toLowerCase()?.includes('en')
            ? 'This mobile number is not registered.'
            : 'رقم الهاتف المحمول هذا غير مسجل.'
        );
      }
      console.log('res by mobile', { data, error, status });
    }
    setLoading(false);
  };

  return (
    <>
      <div className='my-4 text-center text-sm sm:text-lg'>
        <span className='font-bold'>{t('If you have an account,')} </span>
        <span className='text-zinc-500'>
          {t(mail ? 'Log in using your email.' : 'Log in using your mobile.')}
        </span>
      </div>
      {store === 'ksa' && (
        <div className='mb-4 mt-2.5 grid grid-cols-2 text-center text-sm sm:text-lg'>
          <div
            onClick={() => setMail(true)}
            className='cursor-pointer border-b pb-3 text-sm sm:text-lg'
          >
            <span
              className={`${mail ? 'border-b border-b-[#005471] font-bold' : ''} pb-2`}
            >
              {t('Login with email')}
            </span>
          </div>
          <div
            onClick={() => setMail(false)}
            className='cursor-pointer border-b pb-3 text-sm sm:text-lg'
          >
            <span
              className={`${!mail ? 'border-b border-b-[#005471] font-bold' : ''} pb-2`}
            >
              {t('Login with mobile')}
            </span>
          </div>
        </div>
      )}
      <Formik
        initialValues={{ mobilenumber: '', username: '' }}
        onSubmit={handleLogin}
        validationSchema={loginValidation(t, !mail, locale)}
      >
        {mail ? (
          <Form className='*:mb-2.5'>
            <div className='*:mb-1'>
              <Field
                name='username'
                type='email'
                className='w-full rounded-sm border p-1.5 outline-none max-sm:placeholder:text-sm sm:text-lg'
                placeholder={t('Email Address')}
              />
              <ErrorMessage
                name='username'
                component='p'
                className='text-sm text-red-500 sm:text-base'
              />
            </div>
            {/* <div>
              <div className='relative *:mb-1'>
                <Field
                  name='password'
                  type={!show ? 'password' : 'text'}
                  className='w-full rounded-sm border p-1.5 outline-none max-sm:placeholder:text-sm sm:text-lg'
                  placeholder={t('Password')}
                />
                {show ? (
                  <Eye
                    onClick={() => setShow(!show)}
                    className='absolute z-20 -translate-y-1/2 end-2 top-1/2 size-4 text-zinc-500'
                  />
                ) : (
                  <Eyelock
                    onClick={() => setShow(!show)}
                    className='absolute z-20 -translate-y-1/2 end-2 top-1/2 size-4 text-zinc-500'
                  />
                )}
              </div>
              <ErrorMessage
                name='password'
                component='p'
                className='text-sm text-red-500 sm:text-base'
              />
            </div> */}
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-1'>
                <input
                  type='checkbox'
                  id='remember-me'
                  className='size-4 appearance-none rounded-sm border checked:border-black checked:bg-black'
                />
                <label
                  htmlFor='remember-me'
                  className='tlgzinc-600 text-sm sm:text-base'
                >
                  {t('Remember Me')}
                </label>
              </div>
              {/* <button
                onClick={() => {
                  setAuth('Forgot Password');
                  setIsFromLogin('by email');
                }}
                type='button'
                className='w-fit text-base font-bold underline max-sm:text-xs'
              >
                {t('Forgot Password?')}
              </button> */}
            </div>
            <button
              type='submit'
              disabled={loading}
              className='flex w-full items-center justify-center gap-2 rounded-sm bg-black p-1.5 py-2 text-lg font-bold text-white disabled:cursor-not-allowed disabled:opacity-75 max-sm:text-sm'
            >
              {loading && (
                <span className='size-4 animate-spin rounded-full border-r border-t border-white' />
              )}

              <span>{t('Login')}</span>
            </button>
          </Form>
        ) : (
          store === 'ksa' && (
            <Form className='*:mb-2.5'>
              <div className='*:mb-1'>
                <div
                  className={`flex ${locale.includes('ar') ? 'flex-row' : 'flex-row-reverse'} items-center rounded-sm border p-1.5`}
                >
                  <Field
                    name='mobilenumber'
                    className='ltr flex-1 ps-4 outline-none max-sm:placeholder:text-sm sm:text-lg'
                    maxLength={10}
                    type='tel'
                    placeholder='05xxxxxxxx'
                  />
                  <span
                    dir='ltr'
                    className='flex w-16 items-center justify-center gap-2 border-s'
                  >
                    <Image
                      loading='lazy'
                      src={
                        store === 'ksa'
                          ? '/imgs/layout/ksa.png'
                          : '/imgs/layout/uae.jfif'
                      }
                      className='w-5 object-contain '
                      alt={store === 'ksa' ? 'KSA' : 'UAE'}
                    />
                    <span>{store === 'ksa' ? '+966' : '+971'}</span>
                  </span>
                </div>
                <ErrorMessage
                  name='mobilenumber'
                  component='p'
                  className='text-sm text-red-500 sm:text-base'
                />
              </div>
              <span className='block rounded-md bg-yellow-50 px-2 py-1 text-center text-xs text-yellow-600'>
                {locale === 'ar'
                  ? 'تسجيل الدخول بالجوال متاح فقط داخل السعودية . يمكن لعملاء دول الخليج تسجيل الدخول بالايميل'
                  : 'Mobile login is only available within Saudi Arabia, otherwise you can log in via email'}
              </span>
              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-1'>
                  <input
                    type='checkbox'
                    id='remember-me'
                    className='size-4 appearance-none rounded-sm border checked:border-black checked:bg-black'
                  />
                  <label
                    htmlFor='remember-me'
                    className='tlgzinc-600 text-sm sm:text-base'
                  >
                    {t('Remember Me')}
                  </label>
                </div>
                {/* <button
                onClick={() => {
                  setAuth('Forgot Password');
                  setIsFromLogin('by mobile');
                }}
                type='button'
                className='w-fit text-base font-bold underline max-sm:text-xs'
              >
                {t('Forgot Password?')}
              </button> */}
              </div>
              <button
                type='submit'
                disabled={loading}
                className='texlg flex h-8 w-full items-center justify-center rounded-sm bg-black p-1.5 font-bold text-white disabled:cursor-not-allowed sm:text-base'
              >
                {loading ? (
                  <span className='size-4 animate-spin rounded-full border-r border-t border-white' />
                ) : (
                  t('Login')
                )}
              </button>
            </Form>
          )
        )}
      </Formik>
    </>
  );
};

export default Login;
