import browserStorage from 'store';

export const checkoutReducers = (state, action) => {
  switch (action.type) {
    case 'cart':
      return {
        ...state,
        cart: action?.payload,
        items: action?.payload?.items,
        totals: action?.payload?.total_segments,
      };
    case 'shipping_address':
      return {
        ...state,
        shippingAddress: action.payload,
      };
    case 'shipping_methods':
      return {
        ...state,
        shippingMethods: action.payload,
      };
    case 'get_gcc_cities':
      return {
        ...state,
        gcc_cities: action.payload,
      };
    case 'active_shipping_method':
      return {
        ...state,
        activeShippingMethod: action.payload,
      };
    case 'pickup_store':
      return {
        ...state,
        pickupStore: { ...state.pickupStore, ...action.payload },
      };
    case 'payments':
      return {
        ...state,
        payments: action.payload,
      };
    case 'regions':
      return {
        ...state,
        regions: action.payload,
      };
    case 'user_addresses':
      return {
        ...state,
        userAddresses: action.payload,
      };
    case 'clear':
      browserStorage.remove('checkout');
      return {
        cart: {},
        gcc_cities: {},
        items: [],
        totals: [],
        shippingAddress: null,
        userAddresses: [],
        payments: null,
        regions: [],
        mobiles: { '0539449903': true },
        activeShippingMethod: null,
        mobiles: {},
        tel: null,
      };

    case 'get_tel':
      browserStorage.set('checkout', {
        ...browserStorage.get('checkout'),
        tel: action?.payload,
      });
      return {
        ...state,
        tel: action?.payload,
      };

    case 'verify_mobile':
      const { number: mobile, status } = action.payload;
      browserStorage.set('checkout', {
        ...browserStorage.get('checkout'),
        mobiles: { ...state.mobiles, [mobile]: status },
      });
      console.log('first mobile', action.payload);
      return {
        ...state,
        mobiles: { ...state.mobiles, [mobile]: status },
      };
    default:
      return state;
  }
};
