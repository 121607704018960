import { handleLinkByType } from '@/helpers/common/handleLinkByType';
import SingleBanner from './SingleBanner';
import { checkBannerActivity } from '@/utils/global';
import UseLocale from '@/hooks/useLocale';

const StaticBanners = ({ data, sectionType, isMobile }) => {
  const active = isMobile ? 'mobile-active' : 'active';
  // const position = isMobile ? 'mobile-position' : 'position';
  const activeData = data?.value?.filter((item) => item?.[active]);
  const { locale } = UseLocale();
  const width =
    activeData?.length === 4
      ? 300
      : activeData?.length === 3
        ? 600
        : activeData?.length === 2
          ? 800
          : 1600;
  return activeData?.length ? (
    <div className={`grid gap-3 sm:grid-cols-${activeData?.length}`}>
      {activeData?.map((banner, i) => {
        const img = banner[`${isMobile ? 'mobile-' : ''}img-${locale}`];
        const imgSrc = img?.startsWith('http')
          ? img
          : `${process.env.NEXT_PUBLIC_HOME_IMG_BASEURL}/${img}`;
        console.log('home banner section 111', sectionType, data.position, isMobile, imgSrc, img)
        return (
          checkBannerActivity(banner, isMobile) && (
            <SingleBanner
              position={data?.position}
              sectionType={sectionType}
              target={handleLinkByType(banner?.url, banner?.type)}
              index={i}
              key={`static-home-banner-${i}`}
              imgSrc={imgSrc}
              className={`${activeData?.length === 1 ? '' : 'object-cover'} h-auto w-full rounded-md`}
              alt={banner?.['title-' + locale]}
              banner={banner}
              quality={100}
              width={width}
            />
          )
        );
      })}
    </div>
  ) : null;
};

export default StaticBanners;
