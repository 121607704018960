import { useState } from 'react';

const Image = ({
  src,
  alt,
  fetchPriority = 'low',
  loading = 'lazy',
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);

  return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        src={src}
        alt={loaded ? alt : ''}
        loading={loading}
        fetchPriority={fetchPriority}
        onLoad={() => {
          setLoaded(true);
        }}
        className={`transition-opacity duration-300 ${loaded ? 'opacity-100' : 'opacity-0'} ${rest.fill ? 'w-full object-fill ' + rest?.className : rest?.className}`}
        {...rest}
      />
  );
};

export default Image;
