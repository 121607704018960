import { priceFormatedObject, validatePrice } from '@/helpers/product';
import { activeCart } from '@/services/global';
import { deleteCookie, getCookies, setCookie } from 'cookies-next';
import { setHours, setMinutes } from 'date-fns';

export function decodeJwt(token, fromServer = true) {
  if (!token) {
    return;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  if (!fromServer) {
    return JSON.parse(window.atob(base64));
  } else {
    return JSON.parse(Buffer.from(base64, 'base64').toString());
  }
}

export function handleProductForGTMEvents(product, qty) {
  const prices = validatePrice(priceFormatedObject(product));

  const updateItem = {
    category: product?.category
      ? product?.category
      : product?.extension_attributes?.category_names?.map((category) => ({
          name: category?.name,
          category_id: category?.id,
        })),
    sku: product?.sku
      ? product?.sku
      : product?.extension_attributes?.product_sku,
    name: Array.isArray(product?.name) ? product?.name : [product?.name],
    rewrite_url: product?.rewrite_url
      ? product?.rewrite_url
      : product?.extension_attributes?.rewrite_url,
    ...(product?._media_
      ? { _media_: product?._media_ }
      : {
          _media_: {
            image: [
              {
                image: `${process.env.NEXT_PUBLIC_IMG_BASEURL}/catalog/product/${product?.extension_attributes?.product_image}`,
              },
            ],
          },
        }),
    option_text_brand: Array.isArray(product?.brand_name)
      ? product?.brand_name
      : [product?.extension_attributes?.brand_name],
    prices_with_tax: {
      price: prices?.specialPrice,
      original_price: prices?.originalPrice,
    },
    ...(product?.stock
      ? { stock: product?.stock }
      : {
          stock: {
            qty,
            is_in_stock: true,
          },
        }),
  };
  console.log('updateItem////////////', updateItem);
  return updateItem;
}

export function handleTime() {
  const date = new Date();

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const day = days[date.getDay()];
  const month = months[date.getMonth()];
  const dateNum = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  // Adjust for your local time zone offset
  const timezoneOffset = -date.getTimezoneOffset();
  const timezoneOffsetHours = Math.floor(timezoneOffset / 60)
    .toString()
    .padStart(2, '0');
  const timezoneOffsetMinutes = (timezoneOffset % 60)
    .toString()
    .padStart(2, '0');
  const timezoneSign = timezoneOffset >= 0 ? '+' : '-';
  const timezone = `GMT${timezoneSign}${timezoneOffsetHours}${timezoneOffsetMinutes}`;

  return `${day} ${month} ${dateNum} ${year} ${hours}:${minutes}:${seconds} ${timezone}`;
}

export const preventLettersInInput = (event) => {
  const input = event.key;
  // Allow control keys (e.g., backspace, delete)
  if (
    event.ctrlKey ||
    event.altKey ||
    event.metaKey ||
    input === 'Backspace' ||
    input === 'Delete'
  ) {
    return;
  }

  // Check if the input is not a number
  if (isNaN(Number(input))) {
    event.preventDefault();
  }
};

export function findObjectByIdV2(data, targetKey, searchValue) {
  if (data && Array.isArray(data) && data?.length) {
    for (const item of data) {
      if (item[targetKey] == searchValue) {
        // prettyConole(searchValue, item)
        return item;
      }

      if (item?.children_data && item?.children_data.length > 0) {
        const result = findObjectByIdV2(
          item?.children_data,
          targetKey,
          searchValue
        );
        if (result) {
          // prettyConole(searchValue, result)
          return result;
        }
      }
    }
  }
  // Target object not found
  return null;
}

export const handleAddToActiveCart = async (
  handshake,
  order_id,
  push,
  locale
) => {
  // dispatchCart({ type: 'clear' });
  // dispatchCheckout({ type: 'clear' });
  const res = await activeCart(handshake, order_id);
  // const cartDetails = res?.data?.data?.details
  // const cartDetailsStatus = res?.data?.data?.success
  const cart_id = res?.data?.data?.cart_id;

  if (cart_id) {
    setCookie('quote', cart_id);
    setTimeout(() => {
      push(`/${locale}/cart`);
    }, 200);
  }
};

export const validateImgSrc = (src) => {
  // const isProd = process.env.NEXT_PUBLIC_NODE_ENV === "production";
  const newBaseUrl = process.env.NEXT_PUBLIC_BUNNY_IMG_BASEURL;
  // const baseUrl = isProd
  //   ? process.env.NEXT_PUBLIC_IMG_BASEURL
  //   : process.env.NEXT_PUBLIC_IMG_BASEURL
  const baseUrl = process.env.NEXT_PUBLIC_IMG_BASEURL
  const finalSrc = src?.startsWith(baseUrl)
    ? src?.replace(baseUrl, newBaseUrl)
    : src;
  return finalSrc?.replace("/media//", "/media/");
};

export const handleResetStorage = (router, signOut, currentStore) => {
  signOut({ redirect: false });
  Object.keys(getCookies()).forEach((cookie) => deleteCookie(cookie));
  localStorage.clear();
  sessionStorage.clear();
  setCookie('selectedStore', currentStore);
  setCookie('locale', currentStore);
  router.push(router.pathname, '/', { locale: currentStore });
};

export const langSwitcher = (currentLocale) => {
  let dir = currentLocale?.includes('ar') ? 'rtl' : 'ltr';
  let lang = currentLocale?.includes('ar') ? 'ar' : 'en';
  document.querySelector('html').setAttribute('dir', dir);
  document.querySelector('html').setAttribute('lang', lang);
};

export const excludeTimes = [
  setHours(setMinutes(new Date(), 0), 24),
  setHours(setMinutes(new Date(), 30), 24),
  setHours(setMinutes(new Date(), 0), 1),
  setHours(setMinutes(new Date(), 30), 1),
  setHours(setMinutes(new Date(), 0), 2),
  setHours(setMinutes(new Date(), 30), 2),
  setHours(setMinutes(new Date(), 0), 3),
  setHours(setMinutes(new Date(), 30), 3),
  setHours(setMinutes(new Date(), 0), 4),
  setHours(setMinutes(new Date(), 30), 4),
  setHours(setMinutes(new Date(), 0), 5),
  setHours(setMinutes(new Date(), 30), 5),
  setHours(setMinutes(new Date(), 0), 6),
  setHours(setMinutes(new Date(), 30), 6),
  setHours(setMinutes(new Date(), 0), 7),
  setHours(setMinutes(new Date(), 30), 7),
  setHours(setMinutes(new Date(), 0), 8),
  setHours(setMinutes(new Date(), 30), 8),
  setHours(setMinutes(new Date(), 30), 11),
  setHours(setMinutes(new Date(), 0), 12),
  setHours(setMinutes(new Date(), 30), 12),
  setHours(setMinutes(new Date(), 0), 13),
  setHours(setMinutes(new Date(), 30), 13),
  setHours(setMinutes(new Date(), 0), 14),
  setHours(setMinutes(new Date(), 30), 14),
  setHours(setMinutes(new Date(), 0), 15),
  setHours(setMinutes(new Date(), 30), 15),
];

export const countDownHandler = (toDate) => {
  const toDateObj = new Date(toDate?.replace(' ', 'T').concat('Z'));
  const now = new Date();
  const difference = toDateObj.getTime() - now.getTime();
  let timeLeft = { isCountDown: false };

  if (difference > 0) {
    timeLeft = {
      isCountDown: true,
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export function handlePromotionQuery({ banner, locale, i }) {
  const query = `?gtm=homepage&promotion_id=${banner?.id}&creative_name=${banner['img-' + locale]}&creative_slot=${i}`;
  return query;
}

export function checkBannerActivity(banner, isResponsive) {
  if (isResponsive && !banner?.['mobile-active']) return false;
  if (!isResponsive && !banner?.['active']) return false;
  return true;
}

export function userInfo(user) {
  const mobile = user?.custom_attributes
    ?.filter(
      (i) =>
        i.attribute_code === 'mobilenumber' ||
        i.attribute_code === 'mobile_number'
    )
    ?.map((el) => el.value)
    ?.filter(Boolean)
    ?.at(0);
  const newUser = {
    firstname: user?.firstname || '',
    lastname: user?.lastname || '',
    email: user?.email || '',
    telephone: mobile ? '0'?.concat(mobile?.slice(-9)) : '',
  };
  return newUser;
}

export const getErrorMessage = (payment, reason, locale) => {
  if (payment === 'tabby') {
    if (reason === 'Failure') {
      return locale?.includes('en')
        ? 'Sorry, Tabby is unable to approve this purchase. Please use an alternative payment method for your order.'
        : 'نأسف، تابي غير قادرة على الموافقة على هذه العملية. الرجاء استخدام طريقة دفع أخرى.';
    }
    return locale?.includes('en')
      ? 'You aborted the payment. Please retry or choose another payment method.'
      : 'لقد ألغيت الدفعة. فضلاً حاول مجددًا أو اختر طريقة دفع أخرى.';
  }
  return locale?.includes('en')
    ? 'Payment Failed, Please try again'
    : 'فشلت عملية الدفع, برجاء المحاولة مره اخرى';
};
