import Link from 'next/link';
import AddIcon from '../icons/AddIcon';
import RemoveIcon from '../icons/Remove';
import { useTranslation } from 'next-i18next';
import { getCookie, getCookies, setCookie } from 'cookies-next';
import { useState } from 'react';
import { useCart } from '@/context/cart';
import { deleteCartItem, updateCartItem } from '@/services/cart';
import toast from 'react-hot-toast';
import { useRouter } from 'next/router';
import MinusIcon from '../icons/MinusIcon';
import { getRelatedProducts } from '@/utils/cart';
import { priceFormatedObject, validatePrice } from '@/helpers/product';
import { event_add_to_cart, event_remove_from_cart } from '@/utils/gtm';
import { handlePriceByComma } from '@/helpers/common/handlePrice';
import { handleProductForGTMEvents, validateImgSrc } from '@/utils/global';
import NextImage from '../global/NextImage';

const CartItem = ({ itemData, refetchCart, fromCartPage = false }) => {
  const { t } = useTranslation('common');
  const { locale, query } = useRouter();
  const { handshake, mage, quote } = getCookies();
  const [loadingBtn, setLoadingBtn] = useState('');
  const { dispatch: dispatchCart } = useCart();
  const handleDeleteCartItem = async () => {
    setLoadingBtn('deleting');
    const res = await deleteCartItem(handshake, mage, quote, itemData?.item_id);
    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie('quote', res?.data?.quoteId);
      dispatchCart({ type: 'clear' });
    }

    if (res?.status === 200) {
      fromCartPage && (await refetchCart());
      // event_remove_from_cart(
      //   handleProductForGTMEvents(itemData, 1),
      //   1, getCookie("coupon_code") || itemData?.coupon_code
      // );
      event_remove_from_cart(
        handleProductForGTMEvents(itemData, itemData?.qty),
        itemData?.qty,
        getCookie('couponCode') || itemData?.coupon_code
      );
      toast.success(
        locale === 'en' ? 'Item Deleted From Cart!' : 'تم مسح المنتج من العربة'
      );
      dispatchCart({
        type: 'remove',
        payload: { item_id: itemData?.item_id || itemData?.data?.item_id },
      });
      setLoadingBtn('');
      fromCartPage && refetchCart();
    } else {
      toast.error(
        locale === 'en'
          ? 'Error Occured, please try again'
          : 'حذث خطأ, برجاء المحاولة مره اخرى'
      );
      setLoadingBtn('');
    }
  };
  const handleUpdateItem = async (qty, isMinus) => {
    !isMinus ? setLoadingBtn('adding') : setLoadingBtn('subtracting');

    const res = await updateCartItem(
      handshake,
      mage,
      quote,
      itemData?.item_id,
      qty
    );

    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie('quote', res?.data?.quoteId);
      dispatchCart({ type: 'clear' });
    }

    if (res?.status === 200) {
      fromCartPage && (await refetchCart());
      // if (isMinus) {
      //   event_remove_from_cart(
      //     handleProductForGTMEvents(itemData, qty), qty, getCookie("coupon_code") || itemData?.coupon_code
      //   );
      // } else {
      //   event_add_to_cart(handleProductForGTMEvents(itemData, qty), qty, getCookie("coupon_code") || itemData?.coupon_code);
      // }
      +(itemData || itemData?.data)?.qty < qty
        ? event_add_to_cart(
            handleProductForGTMEvents(itemData || itemData?.data, 1),
            1,
            getCookie('coupon_code') ||
              (itemData || itemData?.data)?.coupon_code
          )
        : event_remove_from_cart(
            handleProductForGTMEvents(itemData || itemData?.data, 1),
            1,
            getCookie('coupon_code') ||
              (itemData || itemData?.data)?.coupon_code
          );

      // let quantity = qty
      // if (+product?.qty < qty) {
      //   quantity = qty - +product?.qty
      //   event_add_to_cart(handleProductForGTMEvents(product, quantity), quantity, getCookie("coupon_code") || product?.coupon_code)
      // } else {
      //   quantity = +product?.qty - qty
      //   event_remove_from_cart(handleProductForGTMEvents(product, quantity), quantity, getCookie("coupon_code") || product?.coupon_code);
      // }

      toast.success(
        locale === 'en'
          ? 'Item Updated Successfully'
          : 'تم تعديل كمية المنتج بنجاح'
      );

      // handle related products
      await getRelatedProducts(dispatchCart, itemData);

      dispatchCart({
        type: 'update',
        payload: { item_id: itemData?.item_id || itemData?.data?.item_id, qty },
      });
      setLoadingBtn('');
      fromCartPage && refetchCart();
    } else {
      toast.error(
        res?.error?.error?.message
          ? t(res?.error?.error?.message)
          : locale === 'en'
            ? 'Error Occured, please try again'
            : 'حذث خطأ, برجاء المحاولة مره اخرى'
      );

      if (isMinus) {
        await handleDeleteCartItem();
      }
      setLoadingBtn('');
    }
  };
  const price = validatePrice(priceFormatedObject(itemData));
  return (
    <div
      className={`relative mb-3 flex flex-col gap-4 rounded-md border p-3 ${query?.not_available?.includes(itemData?.extension_attributes?.product_sku) ? 'border-red-600' : ''} `}
    >
      <div className={` flex items-center gap-4 max-sm:text-sm`}>
        {/* {price?.discountPercentage && (
        <div className='absolute px-2 py-1 text-sm text-white bg-black rounded-md end-2 top-4'>
          {+price?.discountPercentage}
          {locale === 'ar' ? '% خصم' : '% OFF'}
        </div>
      )} */}
        <NextImage
          priority={true}
          src={validateImgSrc(`${process.env.NEXT_PUBLIC_IMG_BASEURL}/media/catalog/product${itemData?.extension_attributes?.product_image || itemData?.image?.[0]}`)}
          height={155}
          width={145}
          className='h-full w-32 object-cover'
          alt={itemData?.name}
        />
        <div className='flex flex-1 flex-col gap-2 pe-2 sm:pe-4'>
          <Link
            href={`/product/${itemData?.rewrite_url || itemData?.extension_attributes?.url_key}`}
            className='font-bold max-sm:line-clamp-1'
          >
            {itemData?.name}
          </Link>
          <p className='text-zinc-500 max-sm:text-[11px] sm:text-[15px]'>
            {itemData?.extension_attributes?.category_names?.at(1)?.name ||
              itemData?.category?.[1]?.name ||
              ''}
          </p>
          <div className='flex flex-wrap items-center justify-between gap-2 sm:flex-row-reverse'>
            <div className='flex items-center gap-3'>
              {/* <p className='text-lg font-bold max-sm:text-[11px]'>
              {Number(discountedPrice || originalPrice).toFixed(1)} {t('SR')}
            </p>
            {!!discountedPrice && (
              <p className='line-through text-zinc-500 max-sm:text-sm'>
                {Number(originalPrice).toFixed(1)}
                {t('SR')}
              </p>
            )} */}
              <p className='text-lg font-bold max-sm:text-[11px]'>
                {handlePriceByComma(
                  price?.specialPrice || price?.originalPrice
                )}{' '}
                {t('SR')}
              </p>
              {price?.specialPrice && (
                <p className='text-zinc-500 line-through max-sm:text-[10px]'>
                  {handlePriceByComma(price?.originalPrice)} {t('SR')}
                </p>
              )}
              {itemData?.extension_attributes?.show_discount_price ? (
                <span
                  className={`rounded-md bg-red-500 px-2 py-1 text-sm font-semibold text-white max-sm:text-sm ${price?.specialPrice ? 'visible' : 'invisible'}`}
                >
                  {/* {locale === 'en' ? 'Save' : 'وفر'}{' '} */}
                  {Math.floor(Number(price?.discount) || 0)} %
                </span>
              ) : null}
              {/* {!fromCartPage &&
              itemData?.prices_with_tax?.price !==
                itemData?.prices_with_tax?.original_price && (
                <RenderOriginalPrice />
              )} */}
            </div>
            <div className='flex items-center gap-1'>
              <button
                onClick={
                  itemData?.qty === 1
                    ? handleDeleteCartItem
                    : () => handleUpdateItem(itemData?.qty - 1, true)
                }
                className='flex items-center justify-center rounded-md border p-1.5 disabled:cursor-not-allowed'
                disabled={
                  loadingBtn === 'deleting' || loadingBtn === 'subtracting'
                }
              >
                {itemData?.qty === 1 ? (
                  loadingBtn === 'deleting' ? (
                    <span className='size-4 animate-spin rounded-full border-r border-t border-black' />
                  ) : (
                    <RemoveIcon />
                  )
                ) : itemData?.qty !== 1 ? (
                  loadingBtn === 'subtracting' ? (
                    <span className='size-4 animate-spin rounded-full border-r border-t border-black' />
                  ) : (
                    <MinusIcon className='size-3' />
                  )
                ) : (
                  ''
                )}
              </button>
              <span className='flex w-12 items-center justify-center max-sm:w-6'>
                {itemData?.qty}
              </span>
              <button
                disabled={loadingBtn === 'adding'}
                className='flex items-center justify-center rounded-md border p-1.5 disabled:cursor-not-allowed'
                onClick={() =>
                  handleUpdateItem(itemData?.qty ? itemData?.qty + 1 : 2)
                }
              >
                {loadingBtn === 'adding' ? (
                  <span className='size-4 animate-spin rounded-full border-r border-t border-black' />
                ) : (
                  <AddIcon />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {query?.not_available?.includes(
        itemData?.extension_attributes?.product_sku
      ) ? (
        <p className='text-center text-sm text-red-600'>
          برجاء إزالة هذه المنتجات من العربة نظرا لعدم وجود كميات متاحه
        </p>
      ) : null}
    </div>
  );
};

export default CartItem;
