import { getProductsByCategoryId } from '@/services/category';
import { useEffect, useMemo, useState } from 'react';
import { getCookie } from 'cookies-next';
import { useSWRConfig } from 'swr';

import Title from './Title';
import useSWRMutation from 'swr/mutation';
import ProductSwiper from '../global/swiper/ProductSwiper';
// import { getMultiProductsRating } from '@/services/product/reviews';
import useScreenSize from '@/hooks/display/useDisplay';
import LazyLoading from '../LazyLoading';
import { event_view_item_list } from '@/utils/gtm';
import UseLocale from '@/hooks/useLocale';
import { validateImgSrc } from '@/utils/global';
import NextImage from '../global/NextImage';

const Products = ({ data }) => {
  const [swiper, set_swiper] = useState({});
  const isTabbed = data?.value?.length > 1;
  const { cache } = useSWRConfig();
  const [selectedCat, setSelectedCat] = useState(data?.value?.at(0));
  const [currentTab, setCurrentTab] = useState(data?.value?.[0]?.id);
  const handshake = getCookie('handshake');
  const { locale } = UseLocale();
  const [tabRatings, setTabRatings] = useState([]);

  const { screenWidth } = useScreenSize();
  const isMobile = screenWidth < 400 ? 'mobile-' : '';

  const tab_products_key = `home-products-list/${currentTab}/${locale}`;
  const tab_products_rating_key = `home-products-list/${currentTab}/${locale}/ratings`;

  const {
    trigger: fetchTabProducts,
    data: tabProducts,
    isMutating: isLoadingProducts,
  } = useSWRMutation(
    tab_products_key,
    (_, { arg: tab }) =>
      getProductsByCategoryId(handshake, tab?.id, '', 15, 0, false),
    {
      populateCache: true,
    }
  );

  // const { trigger: fetchProductRatings, isMutating: isLoadingRatings } =
  //   useSWRMutation(
  //     tab_products_rating_key,
  //     (_, { arg: product_ids }) =>
  //       getMultiProductsRating(product_ids, handshake),
  //     {
  //       populateCache: true,
  //       onSuccess: (data) => {
  //         setTabRatings(data);
  //       },
  //     }
  //   );

  useEffect(() => {
    const currentTabCache = cache.get(tab_products_key);

    if (currentTab && !currentTabCache) {
      fetchTabProducts({ id: currentTab }).then(() =>
        // ret
        {
          // const productIds = ret?.data?.data?.products?.map((p) => p.entity_id);
          // fetchProductRatings(productIds);
        }
      );
    } else if (currentTab && currentTabCache) {
      // const productIds = currentTabCache?.data?.data?.products?.map(
      //   (p) => p.entity_id
      // );
      const currentTabRatingsCache = cache.get(tab_products_rating_key);

      if (!currentTabRatingsCache) {
        // fetchProductRatings(productIds);
      } else {
        setTabRatings(currentTabRatingsCache?.data);
      }
    }
  }, [
    currentTab,
    fetchTabProducts,
    // fetchProductRatings,
    cache,
    locale,
    isTabbed,
    tab_products_key,
    tab_products_rating_key,
  ]);

  const changeTabHandler = (tab) => {
    setCurrentTab(tab?.id);
    const selectedCategory = data?.value?.find(
      (category) => category?.id == tab?.id
    );
    setSelectedCat(selectedCategory || {});
  };

  const products = useMemo(() => {
    const activeData = data?.value?.filter((item) => item?.active);
    const currentTabCache = cache.get(tab_products_key);

    const displayedProducts =
      currentTabCache?.data?.data?.data?.products?.length > 0
        ? currentTabCache?.data?.data?.data?.products
        : tabProducts?.data?.data?.products?.length > 0
          ? tabProducts?.data?.data?.products
          : activeData?.at(0)?.products || [];

    const inStockProducts = displayedProducts?.filter(
      (product) => product?.stock?.is_in_stock
    );
    return inStockProducts;
  }, [data?.value, cache, tab_products_key, tabProducts?.data?.data?.products]);

  useEffect(() => {
    // const products = res?.data?.data?.products;
    const currentCategory = selectedCat?.[`title-${locale}`];
    const categoryID = selectedCat?.id;
    setTimeout(() => {
      products?.length &&
        event_view_item_list(
          currentCategory,
          categoryID,
          products,
          getCookie('couponCode') || null
        );
    }, 500);
  }, [locale, products, selectedCat]);

  console.log(selectedCat);
  return (
    <>
      {(data?.[`title-${locale}`] || data?.counter) && (
        <div className='mx-auto'>
          <Title
            swiper={swiper}
            language={locale}
            counter={data?.counter}
            isSwiper={data?.[`title-${locale}`]}
            seeMoreUrl={
              selectedCat?.url
                ? selectedCat?.url
                : data?.[`title-${locale}`] && data?.value?.[0]?.url
            }
            title={data?.[`title-${locale}`]}
            selectedUrlKey={selectedCat?.url}
          />
        </div>
      )}
      {products?.length ? (
        isTabbed ? (
          <section className='grid grid-cols-1 gap-4 max-sm:ms-0 sm:grid-cols-11 md:grid-cols-12'>
            <ul className='no_scroll-640 gray-scroll col-span-full flex gap-4 max-sm:ms-[4.5%] max-sm:items-center max-sm:overflow-x-auto max-sm:overflow-y-hidden sm:col-span-3 sm:max-h-full sm:flex-col sm:overflow-y-auto md:col-span-2'>
              {data?.value?.map((item, key) => (
                <li
                  key={`is-tabbed-${key}`}
                  className={`cursor-pointer whitespace-nowrap text-xs uppercase text-[#6A6262] transition-all last:border-b-0 last:pb-0 hover:font-bold hover:underline sm:border-b sm:pb-4 sm:text-base ${(currentTab ? currentTab == item?.id : data?.value?.[0]?.id == item?.id) ? 'font-bold underline' : 'font-normal'}`}
                  onClick={() => changeTabHandler(item)}
                >
                  {item[`title-${locale}`]}
                </li>
              ))}
            </ul>
            <div className='col-span-full max-sm:mx-0 sm:col-span-4 md:col-span-3'>
              <NextImage
                priority={false}
                src={validateImgSrc(
                  `${selectedCat[`${isMobile}img-${locale}`]}`
                )}
                height={390}
                width={284}
                className='h-auto w-full rounded-md object-cover md:h-[442px] '
                alt='Grid Swiper Img'
              />
            </div>
            <div className='col-span-full sm:col-span-4 md:col-span-7'>
              {isLoadingProducts ? (
                <div className='flex h-full items-center justify-center'>
                  <span className='size-4 animate-spin rounded-full border-r border-t border-black sm:size-8' />
                </div>
              ) : (
                <div className='mx-auto w-full overflow-x-hidden'>
                  <ProductSwiper
                    products={products || []}
                    ratings={tabRatings || []}
                    set_swiper={set_swiper}
                    language={locale}
                    // breakPoints={{
                    //   320: { slidesPerView: 2.5 },
                    //   769: { slidesPerView: 3.2 },
                    // }}
                  />
                </div>
              )}
            </div>
          </section>
        ) : (
          <div className='mx-auto w-full overflow-x-hidden'>
            {isLoadingProducts ? (
              <LazyLoading parentClassName={'min-h-[410px] sm:min-h-[442px]'} />
            ) : (
              <ProductSwiper
                products={products || []}
                ratings={tabRatings || []}
                set_swiper={set_swiper}
                language={locale}
                categoryID={selectedCat?.id}
                categoryName={selectedCat?.[`title-${locale}`]}
              />
            )}
          </div>
        )
      ) : null}
    </>
  );
};

export default Products;

// <div className='flex flex-col gap-2 md:flex-row'>
//   <div className='overflow-x-auto overflow-y-hidden whitespace-nowrap md:w-[20%] md:overflow-y-auto md:overflow-x-hidden  gray-scroll'>
//     <div
//       className='flex p-4 space-x-4 md:h-96 md:flex-col md:space-x-0 md:space-y-4'
//       style={{ scrollSnapType: 'x mandatory' }}
//     >
//       {data?.value?.map((item, key) => (
//         <button
//           key={key}
//           className={`${locale == 'ar' ? 'text-right' : 'text-left'} px-3 py-2 text-gray-400 hover:text-black hover:underline ${currentTab == item?.id && 'text-black underline'}`}
//           onClick={() => changeTabHandler(item)}
//         >
//           {item[`title-${locale}`]}
//         </button>
//       ))}
//     </div>
//   </div>

//   <div className='h-full w-full md:w-[20%]'>
//     <ImgTag
//       sizes='(min-width: 1700px) 215px, (min-width: 1220px) 12.39vw, (min-width: 1000px) calc(20vw - 25px), (min-width: 780px) calc(27vw - 28px), (min-width: 580px) calc(40vw - 22px), calc(48.85vw - 22px)'
//       loading='eager'
//       priority={true}
//       src={`/imgs/category/banner_image.webp`}
//       className='object-cover w-full h-full'
//       alt=''
//     />
//   </div>
//   <div className='w-full md:w-[60%]'>
//     {loading ? (
//       <div
//         className={`mt-auto flex h-full w-full items-center justify-center`}
//       >
//         {' '}
//         <span className='border-t border-r border-black rounded-full size-4 animate-spin' />
//       </div>
//     ) : products?.length ? (
//       <ProductSwiper
//         products={products || []}
//         set_swiper={set_swiper}
//         language={locale}
//         slideNum={3.25}
//       />
//     ) : (
//       <p className='text-center'>There are no products</p>
//     )}
//   </div>
// </div>
