import React, { useState } from 'react';
import Arrow from '@/components/icons/Arrow';
import { useTranslation } from 'next-i18next';

const CountriesList = ({
  onClick,
  store,
  countries,
  country,
  setCountry,
  label = 'country_code',
}) => {
  const { t } = useTranslation('common');
  const [show, setShow] = useState(false);
  return (
    <div className='relative'>
      <div className='inline-flex h-full items-center'>
        <div
          onClick={() => (store === 'ksa' ? setShow((prev) => !prev) : null)}
          className={`flex ${store === 'ksa' && 'cursor-pointer hover:bg-gray-50 hover:text-gray-700'} items-center justify-center gap-2 rounded-md border-e px-4 py-1 text-sm/none text-gray-600 `}
        >
          {store === 'ksa' && <Arrow />}
          <span>{t(country?.[label])}</span>
          {country?.flag}
        </div>
      </div>

      {show && store === 'ksa' && (
        <div
          className='absolute bottom-full end-0 z-10 mt-2  rounded-md border border-gray-100 bg-white shadow-lg'
          role='menu'
        >
          {Object.values(countries.gcc)?.map((item) => (
            <div
              onClick={() => {
                setCountry(item);
                onClick && onClick(item);
                setShow(false);
              }}
              key={item.country_code}
              className='z-50 flex cursor-pointer items-center gap-2 rounded-lg px-4  text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700'
            >
              {item.flag}
              <span>{t(item.label)}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CountriesList;
