import { createContext, useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';

// Create the context
const UrlParamsContext = createContext();

// Custom hook to access the context
export const useUrlParams = () => {
  return useContext(UrlParamsContext);
};

// Context Provider
export const UrlParamsProvider = ({ children }) => {
  const [urlParams, setUrlParams] = useState({});
  const router = useRouter();

  useEffect(() => {
    // Extract URL parameters when the page loads or when the URL changes
    const query = router.query;

    // Set the URL parameters to state
    setUrlParams(query);

    // Clean URL parameters when the page redirects or when the context is not needed
    return () => {
      setUrlParams({});
    };
  }, [router.query]); // Whenever the query changes, update the context

  return (
    <UrlParamsContext.Provider value={{ urlParams, setUrlParams }}>
      {children}
    </UrlParamsContext.Provider>
  );
};
