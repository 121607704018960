// import Image from '@/components/Image';
import { activeData } from '@/constants/global';
import { useRouter } from 'next/router';

const FeaturesFooter = () => {
  const { locale } = useRouter();

  return (
    <section className='divide-x-2 bg-gray-100 max-lg:hidden'>
      <div className=' mx-auto grid w-11/12 max-w-[1440px] grid-cols-4 py-6'>
        {activeData?.map((brand, i) => (
          <div
            key={`active-data-feature-footer-${i}`}
            className='flex items-center justify-around gap-4 border-e border-dark-gray px-5 last:border-e-0'
          >
            <div className='size-9 text-gray-900'>{brand.icon}</div>
            <div className=''>
              <div className='font-bold'>
                {brand?.[`title-${locale.includes('ar') ? 'ar' : 'en'}`]}
              </div>
              <span className='text-gray-500'>
                {brand[`desc-${locale.includes('ar') ? 'ar' : 'en'}`]}
              </span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturesFooter;
