// import { useRouter } from "next/router";
import { useEffect, useState } from 'react';
import { useClient } from '@/hooks/useClient';
import Image from '@/components/global/image';
// import CloseIcon from "@/components/icons/CloseIcon";

const TopHeader = () => {
  // const { locale } = useRouter();
  const { isClient } = useClient();

  const [active, setActive] = useState(
    typeof window !== 'undefined' && window.sessionStorage.getItem('mobile-app')
  );
  useEffect(() => {
    if (!active) {
      setActive(true);
      window.sessionStorage.setItem('mobile-app', 'true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`${
        active && isClient ? 'flex sm:hidden' : 'hidden'
      } z-header relative w-full items-center justify-between bg-white`} // px-3 py-2
    >
      {/* <div className="flex items-center justify-between w-full text-secondary-900">
        <p className="font-bold uppercase text-secondary-900 max-sm:text-xs">
          {locale === "en"
            ? "For an easier shopping experience"
            : "لتجربة تسوق أسهل"}
        </p>
        <button
          aria-label="Mobile App Download button"
          className="bg-black mx-3 border border-secondary-900 text-slate-100 px-4 py-1.5 rounded-full max-sm:text-xs"
          onClick={() =>
            window.open("https://onelink.to/cbc2zj")
          } // need deep link
        >
          {locale === "en" ? "Download App" : "حمّل التطبيق"}
        </button>
      </div>
      <CloseIcon
        onClick={() => {
          setActive(false);
          if (typeof window !== "undefined") {
            window.sessionStorage.setItem("mobile-app", "false");
          }
        }}
        className="text-white cursor-pointer size-4"
      /> */}
      <Image
        onClick={() => window.open('https://onelink.to/cbc2zj')} // need deep link
        src='/imgs/layout/top-header-img.png'
        width={645}
        height={75}
        className='h-auto w-full'
        alt='Top Header Img!'
      />
    </div>
  );
};

export default TopHeader;
