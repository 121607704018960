import { personalizeClient } from '@/aws';
// recommenderCode variables
export const BEST_SELLER = 'Best-sellers';
export const MOST_VIEWED = 'Most-viewed';
export const MY_RECOMMENDATIONS = 'Recommended-for-you';
export const FREQUENTLY_BOUGHT_TOGETHER = 'bought-together';
export const CUSTOMER_WHO_VIEWED_ALSO_VIEWED = 'viewers';

const getRecommendationsParams = (userId, recommenderCode, extraPayload) => ({
  recommenderArn: `${process.env.NEXT_PUBLIC_AWS_RECOMMENDATION_ARN}${recommenderCode}`,
  userId: userId,
  numResults: 25,
  ...extraPayload,
});

export const getAwsPersonalizeProducts = async (
  recommenderId,
  recommenderCode,
  extraPayload
) => {
  try {
    const params = getRecommendationsParams(
      recommenderId,
      recommenderCode,
      extraPayload
    );
    const response = await new Promise((resolve, reject) => {
      personalizeClient.getRecommendations(params, (err, data) => {
        if (err) {
          reject(err); // Reject the promise with the error
        } else {
          resolve(data); // Resolve the promise with the data
        }
      });
    });
    return response;
  } catch (error) {
    throw new Error('Error processing request');
    // throw new Error(error)
  }
};
export const getAwsPersonalizeProductsV2 = async (
  recommenderId,
  recommenderCode,
  extraPayload
) => {
  try {
    const response = await fetch('/api/aws/personalize', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ recommenderId, recommenderCode, extraPayload }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch recommendations');
    }

    const data = await response.json();
    return data.recommendations;
  } catch (error) {
    console.error('Error fetching recommendations:', error);
    throw error;
  }
};
