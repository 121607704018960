import { AppProvider } from '@/context/global/provider';
import { CartProvider } from '@/context/cart/provider';
import { WishlistProvider } from '@/context/wishlist/Provider';
import { CompareProvider } from '@/context/compare/compare-context';
import { CheckoutProvider } from '@/context/checkout/provider';
import { AWSPersonalizeProvider } from '@/context/aws-personalize/aws-personalize-context';
import { UrlParamsProvider } from '@/context/url-params';

export const GlobalProvider = ({ children }) => (
  <AWSPersonalizeProvider>
    <UrlParamsProvider>
      <AppProvider>
        <CompareProvider>
          <CartProvider>
            <WishlistProvider>
              <CheckoutProvider>{children}</CheckoutProvider>
            </WishlistProvider>
          </CartProvider>
        </CompareProvider>
      </AppProvider>
    </UrlParamsProvider>
  </AWSPersonalizeProvider>
);
