import {
  // ADD_PRODUCT_FEES,
  addItemToCart,
  addMultiItemsToCart,
  createCart,
  getCart,
} from '@/services/cart';
import { deleteCookie, getCookie, getCookies, setCookie } from 'cookies-next';
import toast from 'react-hot-toast';
import { getRelatedProducts } from '../cart';
import { event_add_to_cart } from '../gtm';

const addICartItem = async (
  handshake,
  mage,
  quote,
  product,
  qty,
  setCart,
  dispatch,
  configurations,
  Context,
  locale,
  categoryName,
  categoryID,
  relatedProducts
) => {
  const sku = Array.isArray(product?.sku) ? product?.sku?.at(0) : product?.sku;
  const { data, error, status } = await addItemToCart(
    handshake,
    mage,
    quote,
    sku,
    qty,
    Object.values(configurations || {})
  );
  console.log('add to cart from addICartItem', { data, error, status });
  if (error) {
    console.log('add to cart error', error.error);
    return error.error?.message
      ? toast.error(
          error?.error?.message || locale.includes('ar')
            ? 'المنتج الذي تم طلبه غير موجود. تحقق من المنتج وحاول مرة أخرى.'
            : "The product that was requested doesn't exist. Verify the product and try again."
        )
      : toast.error(error?.error);
  }
  if (data?.quoteId && quote != data?.quoteId) {
    dispatch({ type: 'clear' });
    setCookie('quote', data?.quoteId);
  }
  if (data?.error) {
    return toast.error(data?.error);
  }
  if (data?.quoteId && data?.data) {
    await getRelatedProducts(dispatch, product);
    const getCartRes = await getCart(handshake, mage, data?.quoteId);
    console.log('getCartRes?.data?.data', getCartRes?.data?.data);
    dispatch({ type: 'get_cart', payload: getCartRes?.data?.data });
    setCart && setCart(getCartRes?.data?.data);
    dispatch({
      type: 'add_item',
      payload: { ...product, ...data?.data },
    });
    console.log('data?.message', data);
    data?.message &&
      toast.success(
        locale.toLowerCase()?.includes('ar')
          ? 'تم إضافة المنتج إلى العربة بنجاح'
          : 'Item Added To Cart successfully'
      );

    const qtyForGtm = getCookie('qtyForGtm');
    let quantity = qty;
    if (qtyForGtm && qty != 1) {
      if (qty > +qtyForGtm) {
        quantity = qty - +qtyForGtm;
        setCookie('qtyForGtm', qty);
      } else {
        quantity = +qtyForGtm - qty;
        setCookie('qtyForGtm', qty);
      }

      event_add_to_cart(
        {
          ...product,
          categoryName: categoryName
            ? categoryName
            : Array.isArray(product?.name)
              ? product?.name?.at(0)
              : product?.name,
          categoryID: categoryID ? categoryID : +product?.entity_id,
        },
        quantity,
        getCookie('coupon_code') || product?.coupon_code
      );
    } else {
      if (qty == 1) {
        event_add_to_cart(
          {
            ...product,
            categoryName: categoryName
              ? categoryName
              : Array.isArray(product?.name)
                ? product?.name?.at(0)
                : product?.name,
            categoryID: categoryID ? categoryID : +product?.entity_id,
          },
          qty,
          getCookie('coupon_code') || product?.coupon_code
        );
      } else {
        event_add_to_cart(
          {
            ...product,
            categoryName: categoryName
              ? categoryName
              : Array.isArray(product?.name)
                ? product?.name?.at(0)
                : product?.name,
            categoryID: categoryID ? categoryID : +product?.entity_id,
          },
          quantity,
          getCookie('coupon_code') || product?.coupon_code
        );
        setCookie('qtyForGtm', quantity);
      }
    }
    Context?.dispatch({ type: 'open-cart-modal', payload: relatedProducts });
  } else {
    toast.error(
      locale.includes('ar')
        ? 'المنتج الذي تم طلبه غير موجود. تحقق من المنتج وحاول مرة أخرى.'
        : "The product that was requested doesn't exist. Verify the product and try again."
    );
    deleteCookie('quote');
  }
};

export const handleAddItemToCart = async (
  product,
  qty,
  setCart,
  setLoading,
  dispatchCart,
  configurations,
  Context,
  locale,
  categoryName,
  categoryID,
  relatedProducts
) => {
  setLoading(true);
  const { handshake, quote, mage } = getCookies();

  await addICartItem(
    handshake,
    mage,
    quote,
    product,
    qty,
    setCart,
    dispatchCart,
    configurations,
    Context,
    locale,
    categoryName,
    categoryID,
    relatedProducts
  );
  return setLoading(false);
};

// eslint-disable-next-line no-unused-vars
const addCartItems = async (
  handshake,
  mage,
  quote,
  products,
  dispatch,
  context
  // configurations
) => {
  const skus = products?.map((item) => item.sku);
  const { data, error } = await addMultiItemsToCart(
    handshake,
    mage,
    quote,
    skus
  );

  console.log('===========================> data', data);

  if (error)
    return toast.error(
      error?.error?.message ||
        "The product that was requested doesn't exist. Verify the product and try again."
    );
  console.log('quote =====>', quote, data?.quoteId);
    const cart = await getCart(handshake, mage, quote)
    console.log('cart________X_________x__________X', cart)
  if (data?.quoteId && quote !== data?.quoteId) {
    dispatch({ type: 'clear' });
    setCookie('quote', data?.quoteId);
  }
  console.log('add =====++++++++++=======>', data)
  dispatch({ type: 'get_cart', payload: cart?.data?.data });
  context.dispatch({'type':'open-cart-modal'})
  data?.message && toast.success(data?.message);

  console.log({ skus, data, error });
};

export const handleAddItemsToCart = async (
  products,
  setLoading,
  dispatchCart,
  context,
) => {
  setLoading(true);
  const { handshake, quote, mage } = getCookies();
  // 1 check if user has a cart. If not generate one and save it to cookies
  if (!quote) {
    const { data: newCart } = await createCart(handshake, mage);
    // 2 .5 Set the cookie with the id of the new cart in users browser and add item to cart
    if (newCart?.cart_id) {
      setCookie('quote', newCart?.cart_id);
      await addCartItems(
        handshake,
        mage,
        newCart?.cart_id,
        products,
        dispatchCart,
        context
      );
      return setLoading(false);
    }
    return setLoading(false);
  }
  //If user already has a cart just add the item to their existing cart
  await addCartItems(
    handshake,
    mage,
    quote,
    products,
    dispatchCart,
    context
  );
  return setLoading(false);
};
