import AWS from 'aws-sdk';
AWS.config.update({
  region: process.env.AWS_REGION,
  credentials: new AWS.Credentials(
    process.env.AWS_ACCESS_KEY,
    process.env.AWS_SECRET_KEY
  ),
});
const personalizeEvents = new AWS.PersonalizeEvents();
export const personalizeClient = new AWS.PersonalizeRuntime();
export default personalizeEvents;
