import { validatePrice } from '@/helpers/product';

export const productSchema = (product, breadcrumb, locale) => {
  const price = validatePrice(product?.prices_with_tax);

  const modified_breadcrumb = [
    {
      name: locale === 'en' ? "Home" : "الرئيسية",
      url: ""
    },
    ...breadcrumb
  ];

  // Function to dynamically generate breadcrumb URLs
  const generateBreadcrumbs = (breadcrumbList) => {
    let currentPath = process.env.NEXT_PUBLIC_WEBSITE_URL;

    return breadcrumbList.map((item, index) => {
      // Construct the full URL for each breadcrumb
      currentPath += item.url + "/";
      return {
        "@type": "ListItem",
        "position": index + 1,
        "item": {
          "@id": currentPath,
          "name": item.name
        }
      };
    });
  };

  return {
    "microdata": null,
    "jsonld": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "logo": process.env.NEXT_PUBLIC_WEBSITE_URL + "/imgs/layout/Logo.png",
        "url": process.env.NEXT_PUBLIC_WEBSITE_URL + "/"
      },
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": generateBreadcrumbs(modified_breadcrumb)
      },
      {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": process.env.NEXT_PUBLIC_WEBSITE_URL + '/',
        "potentialAction": [
          {
            "@type": "SearchAction",
            "target": process.env.NEXT_PUBLIC_WEBSITE_URL + "/api/search?word={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        ]
      },
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "description": product?.meta_description,
        "sku": product?.sku,
        "mpn": product?.sku,
        "url": process.env.NEXT_PUBLIC_WEBSITE_URL + '/product/' + product?.rewrite_url,
        "image": [
          product?._media_?.image?.at(0)?.image
        ],
        "brand": {
          "@type": "Brand",
          "name": product?.option_text_brand?.at(0) || ''
        },
        "offers": [
          {
            "price": (price?.specialPrice || price?.originalPrice || 0)?.toFixed(2),
            "priceCurrency": "SAR",
            "priceValidUntil": product?.prices_with_tax?.discounted_price_to || '',
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock",
            "url": product?.rewrite_url,
            "@type": "Offer",
            "seller": {
              "@type": "Organization",
              "name": locale == 'ar' ? "قصر الأواني" : "Qasr Al-Awani"
            }
          }
        ],
        "name": product?.name
      }
    ],
    "graph": null
  };
};

export const homeSchema = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    name: 'qasralawani',
    alternateName: 'قصر الأواني',
    url: 'https://www.qasralawani.com/',
    logo: 'https://www.qasralawani.com/imgs/layout/Logo.png',
    sameAs: [
      'https://www.facebook.com/Qasrawani',
      'https://x.com/Qasralawani',
      'https://www.instagram.com/qasralawani',
      'https://www.youtube.com/@QasrAwani',
      'https://www.tiktok.com/@qasralawani',
      'https://www.snapchat.com/add/qasrawani',
    ],
  };
};
