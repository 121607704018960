import { loginByEmail, loginByMobile } from '@/services/auth';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'next-i18next';

export default function TimerCounter() {
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(240); // 4 minutes in seconds

  useEffect(() => {
    // Timer logic
    let timer;
    if (timeLeft > 0) {
      timer = setTimeout(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [timeLeft]);

  const handshake = getCookie('handshake');
  const { locale } = useRouter();
  const { t } = useTranslation('common');

  const resendOTP = async () => {
    const mail = getCookie('mail');
    const username = getCookie('username');
    const mobilenumber = getCookie('mobilenumber');

    setLoading(true);
    if (mail) {
      const res = await loginByEmail(handshake, username);
      if (res?.data && res?.status === 200) {
        toast.success(res?.data?.data?.successmsg);
        setLoading(false);
        setTimeLeft(240);
        return;
      }
      toast.error(t(res?.error?.error));
    } else {
      const { data, status } = await loginByMobile(
        handshake,
        mobilenumber,
        locale
      );

      if (
        status == 200 &&
        String(data?.data?.success) == 'true' &&
        data?.data?.successmsg
      ) {
        toast.success(data?.data?.successmsg);
        setTimeLeft(240);
      }
    }
    setLoading(false);
  };

  return Math.floor(timeLeft / 60) == 0 &&
    (timeLeft % 60).toString().padStart(2, '0') == '00' ? (
    <button
      onClick={resendOTP}
      className='flex w-48 rounded-sm bg-green-500 p-1.5 px-4 py-2 py-2.5 text-sm font-bold text-white hover:bg-green-600 disabled:cursor-not-allowed sm:text-lg justify-center'
    >
      {loading ? (
        <span className='size-4 animate-spin rounded-full border-r border-t border-white' />
      ) : (
        t('Resend')
      )}
    </button>
  ) : (
    <div className='col-span-3'>
      {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
    </div>
  );
}
