import { postFetcher } from '@/services/global';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

const useInfiniteProducts = ({
  products,
  categoryID,
  fromCategory = true,
  word = '',
  pageSize = 30,
  brand,
}) => {
  const [allProducts, setAllProducts] = useState(products || []);

  const [pageIndex, setPageIndex] = useState(1);
  const [allFilterKeys, setAllFilterKeys] = useState('');
  const [allSortingKeys, setAllSortingKeys] = useState('');
  const [moreProductsIsLoading, setMoreProductsIsLoading] = useState(false);
  const { locale } = useRouter();
  const { cache, mutate } = useSWRConfig();
  const cachedKeys = cache.keys()

  const res = useSWRMutation(
    fromCategory
      ? `/api/category/pages/getMoreProducts?pageIdx=${pageIndex}&categoryID=${categoryID}&${allSortingKeys}&${allFilterKeys}`
      : `/api/search?word=${word}&pageNo=${pageIndex}&pageSize=${pageSize}&${allSortingKeys}&${allFilterKeys}`,
    postFetcher,
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      populateCache: true,
    }
  );

  const handleGettingProductsAndCaching = useCallback(
    async (url_cach) => {
      setMoreProductsIsLoading(true);
      setPageIndex((prev) => prev + 1);

      let result = await res?.trigger(
        fromCategory
          ? {
              pageNo: pageIndex,
              pageSize: pageSize,
              categoryID,
              locale,
              filterKeys: allFilterKeys,
              sortKeys: allSortingKeys,
            }
          : {
              pageNo: pageIndex,
              pageSize: pageSize,
              word,
              filterKeys: allFilterKeys,
              sortKeys: allSortingKeys,
            }
      );

      mutate({
        [url_cach]: result,
      });

      result?.products?.length &&
        result?.products != undefined &&
        setAllProducts((prev) => [...prev, ...result?.products]);

      if (result?.products != undefined && Array.isArray(result?.products)) {
        if (result?.products?.length < pageSize) {
          setMoreProductsIsLoading(true);
        } else {
          setMoreProductsIsLoading(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allFilterKeys, allSortingKeys, categoryID, mutate, pageIndex, res]
  );
  
  useEffect(() => {
    let updateArr = [];
    let index = 1;

    for (const currCachedKey of cachedKeys) {
      if (
        currCachedKey?.includes('/api/category/pages/getMoreProducts') && currCachedKey?.includes(categoryID)
      ) {
        console.log('currCachedKey ------ ', currCachedKey)
        const selectedProducts = cache.get(currCachedKey)?.data?.products;
        const selectedData = cache.get(currCachedKey)?.data;
        index = selectedData?.pageParam;
        updateArr = [...updateArr, ...(selectedProducts?.length ? selectedProducts : [])]
      }
    }

    if (products?.length && updateArr?.length) {

      setAllProducts([...products, ...updateArr]);
      setPageIndex(index);
      setMoreProductsIsLoading(false);
    } else {
      setAllProducts(products);
      setMoreProductsIsLoading(false);
      setPageIndex(1);
      setAllFilterKeys('');
      setAllSortingKeys('' + (brand ? '&brand=' + brand : ''));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cache, categoryID,
    // asPath, productsData, cachingKey
  ]);

  useEffect(() => {
    if (!moreProductsIsLoading && !res?.isMutating) {
      const target = document.getElementById('afterProductsSection');
      const url_cach = fromCategory
        ? `/api/category/pages/getMoreProducts?pageIdx=${pageIndex}&categoryID=${categoryID}&${allSortingKeys}&${allFilterKeys}`
        : `/api/search?word=${word}&pageNo=${pageIndex}&pageSize=${pageSize}&${allSortingKeys}&${allFilterKeys}`;
      const observer = new IntersectionObserver(
        async (entries) => {
          if (entries[0].isIntersecting) {
            (!(pageIndex > res?.data?.pages) && !cache.get(url_cach)) && handleGettingProductsAndCaching(url_cach);
          }
        },
        { threshold: 1.0 }
      );
      target && observer.observe(target);

      return () => observer.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageIndex,
    moreProductsIsLoading,
    cache,
    mutate,
    res,
    categoryID,
    handleGettingProductsAndCaching,
    allSortingKeys,
    allFilterKeys,
    word,
  ]);

  return {
    allProducts,
    setAllProducts,
    setAllFilterKeys,
    allFilterKeys,
    allSortingKeys,
    setAllSortingKeys,
    setPageIndex,
    setMoreProductsIsLoading,
  };
};

export default useInfiniteProducts;
