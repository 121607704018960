import { useRouter } from "next/router";
import { useEffect } from "react";

const usePreventScrollOnBack = () => {
  const router = useRouter()

  useEffect(() => {
    const handlePopState = (e) => {
      e.preventDefault();
      router.back
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [router]);
};

export default usePreventScrollOnBack