import { checkoutReducers } from './reducers';
import store from 'store';
const { useReducer, createContext, useEffect } = require('react');
export const CheckoutContext = createContext();

export const CheckoutProvider = ({ children }) => {
  const [checkout, dispatch] = useReducer(
    checkoutReducers,
    store.get('checkout')
      ? store.get('checkout')
      : {
          cart: {},
          items: [],
          gcc_cities: {},
          totals: [],
          shippingAddress: null,
          shippingMethods: [],
          activeShippingMethod: null,
          pickupStore: {},
          userAddresses: [],
          payments: null,
          regions: [],
          mobiles: {},
          tel: null,
        }
  );

  useEffect(() => {
    store.set('checkout', checkout);
  }, [checkout]);

  return (
    <CheckoutContext.Provider value={{ checkout, dispatch }}>
      {children}
    </CheckoutContext.Provider>
  );
};
