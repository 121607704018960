import SingleBanner from '@/components/home/SingleBanner';
import { handleLinkByType } from '@/helpers/common/handleLinkByType';
import UseLocale from '@/hooks/useLocale';

import { Swiper, SwiperSlide } from 'swiper/react';

const StaticBannerSwiper = ({ data, sectionType, isMobile }) => {
  const activeData = data?.value?.filter(
    (item) => item?.[`${isMobile ? 'mobile-' : ''}active`]
  );
  const { locale } = UseLocale();
  const bgImg = data?.[isMobile ? `mobile-img-${locale}` : `img-${locale}`];
  return activeData?.length
    ? data?.active && (
        <Swiper
          spaceBetween={8}
          breakpoints={{
            320: { slidesPerView: 2.5 },
            461: { slidesPerView: 3.5 },
            769: { slidesPerView: 5 },
          }}
          style={
            sectionType === 'offers-slider-bg'
              ? {
                  background: `url(${bgImg}) center 0  / cover no-repeat `,
                  padding: '120px 16px 16px',
                  borderRadius: '8px',
                }
              : {}
          }
        >
          {activeData?.map((banner, i) => {
            return (
              <SwiperSlide key={`static-banner-swiper-${i}`}>
                <SingleBanner
                  position={data?.position}
                  sectionType={sectionType}
                  target={handleLinkByType(banner?.url, banner?.type)}
                  index={i}
                  key={`static-banner-swiper-2-${i}`}
                  imgSrc={banner['img-' + locale]}
                  className='h-auto w-full cursor-pointer rounded-md object-cover'
                  alt={banner?.['title-' + locale]}
                  banner={banner}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )
    : null;
};

export default StaticBannerSwiper;
