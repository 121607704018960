import Link from 'next/link';
import { event_select_promotion, event_view_promotion } from '@/utils/gtm';
import useInViewPort from '@/hooks/global/useInViewPort';
import NextImage from '../global/NextImage';
import { validateImgSrc } from '@/utils/global';

export default function SingleBanner({
  target,
  index,
  className,
  imgSrc,
  alt,
  banner,
  position,
  sectionType = null,
  quality,
  width
}) {
  function fireViewPromotion() {
    setTimeout(() => {
      event_view_promotion(imgSrc, index, position, banner?.id, sectionType);
    }, 500);
  }
  const [ref] = useInViewPort(fireViewPromotion);

  return banner?.url ? (
    <Link
      onClick={() =>
        event_select_promotion(imgSrc, index, position, banner?.id, sectionType)
      }
      href={target}
      ref={ref}
      key={`single-banner-${index}`}
    >
      <NextImage
        priority={true}
        src={validateImgSrc(imgSrc)}
        height={528}
        width={width || 780}
        className={className}
        alt={alt}
        quality={quality}
      />
    </Link>
  ) : (
    <NextImage
      priority={true}
      src={validateImgSrc(imgSrc)}
      height={528}
      width={780}
      className={className}
      alt={alt}
    />
  );
}
