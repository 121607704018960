import Arrow from '@/components/icons/Arrow';
import SearchIcon from '@/components/icons/SearchIcon';
import Image from '@/components/Image';
import { useCart } from '@/context/cart';
import { useCheckout } from '@/context/checkout';
import { handleResetStorage } from '@/utils/global';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import useStore from '@/hooks/useStore';

const Search = ({
  t,
  setInputValue,
  inputValue,
  ourSearchInput,
  // ourSearchButton,
}) => {
  const { dispatch: dispatchCart } = useCart();
  const { dispatch: dispatchCheckout } = useCheckout();
  const router = useRouter();
  const { locale } = router;
  const store = useStore(locale);

  return (
    <div
      className={`order-3 flex w-full items-center gap-3 max-md:mt-2 max-md:flex md:order-2 md:w-fit md:flex-1`}
    >
      <div className='flex flex-1 items-center gap-3 rounded-md border ps-2.5'>
        <SearchIcon className='size-3.5 text-zinc-500' />
        <input
          ref={ourSearchInput}
          placeholder={t('Search For Products')}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          className='flex-1 rounded-e-md py-2 text-sm outline-none placeholder:text-sm sm:text-base sm:placeholder:text-sm'
        />
      </div>
      {/* <button ref={ourSearchButton} className='w-fit rounded-e-md border border-black bg-black px-10 py-2 text-sm text-white sm:text-base'>
        {t('Search')}
      </button> */}

      <div className='group relative rounded-md border p-1 md:hidden'>
        <div className='flex cursor-pointer items-center gap-2'>
          <Image
            loading='lazy'
            src={
              store === 'ksa' ? '/imgs/layout/sa.svg' : '/imgs/layout/ae.svg'
            }
            className='h-[29px] w-[33px] object-contain'
            alt={store === 'ksa' ? 'Saudi Arabia Flag' : 'UAE Flag'}
          />
          {/* <span className='mb-0.5'>
            {t(locale === 'ar' ? 'Saudi Arabia' : 'UAE')}
          </span> */}
          <Arrow className='text-gray-500' />
        </div>

        <div className='absolute -start-16 top-full z-[100] hidden min-w-32 max-w-32 rounded-md bg-white p-2 text-black shadow-lg group-hover:block'>
          {store === 'uae' ? (
            <span
              onClick={() => {
                const currentLocale = locale?.includes('ar') ? 'ar' : 'en';
                dispatchCart({ type: 'clear' });
                dispatchCheckout({ type: 'clear' });
                handleResetStorage(router, signOut, currentLocale);
              }}
              // href={{ pathname, query }}
              // as={asPath}
              // locale='ar'
              className='mb-3 flex items-center gap-2'
            >
              <Image
                loading='lazy'
                src='/imgs/layout/sa.svg'
                className='object-contain'
                alt='KSA Flag'
              />
              <span className='mb-0.5 cursor-pointer'>{t('Saudi Arabia')}</span>
            </span>
          ) : null}
          {store === 'ksa' ? (
            <span
              onClick={() => {
                const currentLocale = locale?.includes('ar')
                  ? 'ar-ae'
                  : 'en-ae';
                dispatchCart({ type: 'clear' });
                dispatchCheckout({ type: 'clear' });
                handleResetStorage(router, signOut, currentLocale);
              }}
              // href={{ pathname, query }}
              // as={asPath}
              // locale='ar-ae'
              className='flex items-center gap-2'
            >
              <Image
                loading='lazy'
                src='/imgs/layout/ae.svg'
                className='object-contain'
                alt='KSA Flag'
              />
              <span className='mb-0.5 cursor-pointer'>{t('UAE')}</span>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Search;
