import Image from "next/image";

const imgLoader = ({ src, width, quality }) => {
  return `${src}?width=${width}&quality=${quality || 80}&format=webp`;
};

const NextImage = ({
  className,
  src,
  alt,
  width,
  height,
  priority = false,
  ...props
}) => {
  return (
    <Image
      src={src}
      alt={alt}
      className={className}
      priority={priority}
      width={width}
      height={height}
      loader={imgLoader}
      {...props}
    />
  );
};

export default NextImage;
