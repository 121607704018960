import {
  KuwaitFlag,
  SaudiFlag,
  BahrainFlag,
  OmanFlag,
  EmiratesFlag,
  QaterFlag,
} from '@/components/icons/gcc-flags';

export const countries = {
  gcc: {
    sa: {
      label: 'saudi',
      country_code: '966',
      country_id: 'SA',
      placeholder: '0551234567',
      error: '05**',
      flag: <SaudiFlag width={20} />,
    },
    kw: {
      label: 'kuwait',
      country_code: '965',
      country_id: 'KW',
      placeholder: '51234567',
      error: '(5, 6, 9)-**',
      flag: <KuwaitFlag width={20} />,
    },
    om: {
      label: 'oman',
      country_code: '968',
      country_id: 'OM',
      placeholder: '92123456',
      error: '9**',
      flag: <OmanFlag width={20} />,
    },
    qa: {
      label: 'qater',
      country_code: '974',
      country_id: 'QA',
      placeholder: '33123456',
      error: '(5:7)-**',
      flag: <QaterFlag width={20} />,
    },
    bh: {
      label: 'bahrain',
      country_code: '973',
      country_id: 'BH',
      placeholder: '061234567',
      error: '06**',
      flag: <BahrainFlag width={20} />,
    },
    // Add more GCC countries as needed
  },
  ae: {
    label: 'uae',
    country_code: '971',
    country_id: 'AE',
    placeholder: '0501234567',
    error: '05**',
    flag: <EmiratesFlag width={20} />,
  },
};
