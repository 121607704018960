import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useScrollRestoration = () => {
  const router = useRouter();

  useEffect(() => {

    const handleRouteChange = (url) => {
      // Save scroll position before leaving the page
      console.log('url_____________1', url);
      sessionStorage.setItem(`scrollPos:${router.asPath}`, window.scrollY);
    };

    const restoreScrollPosition = (url) => {
      setTimeout(() => {
        console.log('url_____________2', url);
        // Restore scroll position after navigation
        const scrollY = sessionStorage.getItem(`scrollPos:${url}`);
        if (scrollY) {
          window.scrollTo(0, parseInt(scrollY));
        }
      }, 500)
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', restoreScrollPosition);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', restoreScrollPosition);
    };
  }, [router]);
};

export default useScrollRestoration;
